import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/store';
import { actions as globalPropsActions } from '../../redux/globalProps';

const useGlobalPropsUpdate = (isFastEnv: boolean) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isFastEnv) {
            dispatch(globalPropsActions.fetchGlobalProps());
        }
    }, []);
};

export default useGlobalPropsUpdate;
