import debounce from 'lodash/debounce';
import { useContext, useEffect, useRef } from 'react';
import BottomCtaContext from '../contexts/bottomCtaContext';

// It is used for Cookie Banner on mobile devices to change the Cookie Banner position relying on the Bottom Button
// "debounce" is used to avoid multiple calls caused by rerenders.
// It updates the Bottom Button ID and "dependency" value.
// The "dependency" value is used to force recheck the Bottom Button position even when "id" is not changed.
export const useBottomCta = () => {
    const { setBottomCtaId, setDependency } = useContext(BottomCtaContext);

    const debounced = debounce((id) => {
        setBottomCtaId(id);
        setDependency(Date.now());
        debounced?.cancel();
    }, 100);

    const debouncedRef = useRef(debounced);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => debounced?.cancel(), []);

    return {
        setBottomCtaId: debouncedRef.current,
    };
};
