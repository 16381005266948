import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Head from 'next/head';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import styles from './index.module.css';
import revalidate from '../lib/revalidate';
import { ContentfulDelivery } from '../lib/contentfulDelivery';
import { IPageProps } from './[nameInUrl]';
import PageSections from '../components/sections';
const StartOrderButton = dynamic(() => import('../components/atoms/startOrderButton/startOrderButton'));
const Footer = dynamic(() => import('../components/organisms/footer'));
import BaseHeader from '../components/organisms/header/baseHeader';

import { getLocationById } from '../common/services/locationService';
import { GTM_START_PICKUP_ORDER } from '../common/services/gtmService/constants';
import {
    useOrderLocation,
    useDomainMenu,
    useNotifications,
    useConfiguration,
    useGlobalProps,
    usePersonalization,
} from '../redux/hooks';
import { isLocationOrderAheadAvailable } from '../lib/locations';
import getBrandInfo from '../lib/brandInfo';
import getFeatureFlags from '../lib/getFeatureFlags';
import { getHomePageTitle } from '../common/helpers/getPageTitle';
import { getPageDescription } from '../common/helpers/getPageDescription';
import { getCanonicalUrl } from '../common/helpers/getCanonicalUrl';
import { FeatureFlagsContext } from '../redux/hooks/useFeatureFlags';
import { PageContentWrapper } from '../components/sections/PageContentWrapper';
import { getOrganizationSchema } from '../common/helpers/getOrganizationSchema';
import { useLocationUnavailableError } from '../common/hooks/useLocationUnavailableError';
import hasPersonalizedContent from '../lib/hasPersonalizedContent';
import BrandLoader from '../components/atoms/BrandLoader';
import { optimizeContentfullData } from '../lib/cmsOptimization';
const LoyaltyPointsBanner = dynamic(() => import('../components/organisms/loyaltyPointsBanner'));
import isFastEnv from '../lib/isFastEnv';
import useGlobalPropsUpdate from '../common/hooks/useGlobalPropsUpdate';
import { useBottomCta } from '../common/hooks/useBottomCta';

export default function Home(props: IPageProps): JSX.Element {
    const { page, isPreviewMode, featureFlags, hasPersonalizedContent, isFastEnv } = props;
    const { brandName } = getBrandInfo();
    const globalProps = useGlobalProps();

    const { alertBanners, navigation, footer, userAccountMenu } = globalProps;
    const webNavigation = navigation?.items?.find((item) => item.fields.name === 'Web');
    const {
        configuration: { isOAEnabled },
    } = useConfiguration();
    const router = useRouter();
    const locationId = router.query?.locationId;
    const dispatch = useDispatch();
    const {
        actions: { getDomainMenu },
    } = useDomainMenu();

    const {
        actions: { enqueueError },
    } = useNotifications();
    const {
        actions: { setPickupLocation },
        currentLocation,
    } = useOrderLocation();

    const { pushLocationUnavailableError } = useLocationUnavailableError();

    useGlobalPropsUpdate(isFastEnv);

    useEffect(() => {
        if (locationId) {
            if (currentLocation?.id === locationId) {
                router.push('/menu');
                return;
            }

            const handleLocationIdInQuery = async () => {
                try {
                    const location = await getLocationById({
                        locationId: Array.isArray(locationId) ? locationId[0] : locationId,
                    });

                    const isOrderAheadAvailable = location && isLocationOrderAheadAvailable(location, isOAEnabled);

                    if (!location.isDigitallyEnabled) {
                        pushLocationUnavailableError(location);
                    }
                    if (isOrderAheadAvailable) {
                        dispatch({ type: GTM_START_PICKUP_ORDER });
                        setPickupLocation(location);
                        getDomainMenu(location);
                    } else {
                        enqueueError({
                            title: 'Online ordering is not available at this location',
                            message: 'Please select another location',
                        });
                        router.push('/locations');
                    }
                } catch (error) {
                    enqueueError({
                        title: 'Location is not found',
                        message: 'Please select another location',
                    });
                    router.push('/locations');
                }
            };

            handleLocationIdInQuery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId, isOAEnabled, currentLocation?.id]);

    const { metaTitle, hideFooter } = page.fields;
    const organizationSchema = getOrganizationSchema();

    const { loading: isPersonalizationLoading } = usePersonalization();

    const { setBottomCtaId } = useBottomCta();
    setBottomCtaId('start-order-btn');

    return (
        <FeatureFlagsContext.Provider value={{ featureFlags }}>
            <BaseHeader
                alertBanners={alertBanners}
                webNavigation={webNavigation}
                userAccountMenu={userAccountMenu}
                isPreviewMode={isPreviewMode}
                showSignUpBanner
            />
            <LoyaltyPointsBanner />
            <div className="container">
                <Head>
                    <title>{getHomePageTitle(brandName, metaTitle)}</title>
                    <meta name="description" content={getPageDescription(page)} />
                    <link rel="canonical" href={getCanonicalUrl(page, process.env.NEXT_PUBLIC_APP_URL)} />
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }}
                    />
                </Head>
                <PageContentWrapper>
                    <h1 className="visually-hidden">Homepage</h1>
                    {hasPersonalizedContent && isPersonalizationLoading ? (
                        <BrandLoader className={styles.loader} />
                    ) : (
                        <PageSections pageSections={page.fields.section} />
                    )}
                    <StartOrderButton />
                </PageContentWrapper>
            </div>
            {footer && !hideFooter && <Footer footer={footer} />}
        </FeatureFlagsContext.Provider>
    );
}

export const getStaticProps: GetStaticProps = async (context) => {
    // TODO: Move navigation, menuCategories, alertBanners & featureFlags  to _app.tsx once getStaticProps is supported there
    // https://github.com/zeit/next.js/discussions/10949

    const page = await ContentfulDelivery(context.preview).getPage();
    const featureFlags = getFeatureFlags();
    const optimizedPage = optimizeContentfullData(page);

    return {
        props: {
            page: optimizedPage,
            hasPersonalizedContent: hasPersonalizedContent(optimizedPage.fields.section),
            isPreviewMode: !!context?.preview,
            featureFlags,
            isFastEnv: isFastEnv(),
        },
        revalidate: revalidate(),
    };
};
