import getBrandInfo, { BRANDS } from '../../lib/brandInfo';

export const getOrganizationSchema = () => {
    const { brandId, brandName } = getBrandInfo();
    const brandIdLowercase = brandId.toLowerCase();

    const sameAsMap = {
        [BRANDS.arbys.brandId]: [
            'https://www.facebook.com/arbys/',
            'https://twitter.com/Arbys/',
            'https://www.instagram.com/arbys/',
            'https://www.youtube.com/user/arbys',
        ],
        [BRANDS.bww.brandId]: [
            'https://www.facebook.com/BuffaloWildWings/',
            'https://twitter.com/bwwings/',
            'https://www.instagram.com/bwwings/',
            'https://www.youtube.com/user/buffalowildwings',
        ],
    };

    const organizationSchema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: brandName,
        url: process.env.NEXT_PUBLIC_APP_URL,
        logo: `${process.env.NEXT_PUBLIC_APP_URL}/brands/${brandIdLowercase}/logo.svg`,
        sameAs: sameAsMap[brandId],
    };

    return organizationSchema;
};
