import { Entry } from 'contentful';
import {
    IPageFields,
    IMenuCategoryFields,
    ITapListMenuCategoryFields,
    IProductFields,
} from '../../@generated/@types/contentful';
import { InspireCmsEntry } from '../types';

export const getCanonicalUrl = (
    source: InspireCmsEntry<IPageFields> | Entry<IMenuCategoryFields | ITapListMenuCategoryFields | IProductFields>,
    defaultCanonicalUrl: string
): string => {
    // return default url if we can't parse the URL ex. - homepage
    const parentUrl = defaultCanonicalUrl.match(/(.+\/).+\//)?.[1] || defaultCanonicalUrl;
    return source?.fields?.canonicalUrl ? `${parentUrl}${source.fields.canonicalUrl}/` : defaultCanonicalUrl;
};
